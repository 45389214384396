import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'
import videojs from 'video.js'
import SiteHelmet from '../components/SiteHelmet'
import SiteNav from '../components/SiteNav'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import VideoPlayer from '../components/VideoPlayer'
import { TWITCH_ACCOUNT_TEST } from '../config'
import moment from 'moment-timezone'

class LiveStream extends React.Component {
  render() {
    const events = this.props.data.allMarkdownRemark.edges
    const futureEvents = events.filter(e => moment().isBefore(e.node.frontmatter.startTime))
    const currentEvents = events.filter(e => moment().isBefore(e.node.frontmatter.endTime) && moment().isAfter(e.node.frontmatter.startTime))
    const current = currentEvents[0] && currentEvents[0].node
    const future = futureEvents[0] && futureEvents[0].node

    return (
      <Layout full>
        <SiteHelmet title="MB productions - live streaming visuals" description="We do live streaming from time to time. Tune in here for some quality tunes and visuals!">
        </SiteHelmet>
        <SiteNav sticky />

        <HeaderGeneric title="live streaming visuals"><p>If you get sick of looking at us, look at this instead!</p></HeaderGeneric>

        <main id="main" className="stream">
          <section id="video" className="stream-column stream-column__stream">
            <VideoPlayer vid="https://stream.hardwarehookups.com.au/vj/hls/test.m3u8" current={current} future={future}></VideoPlayer>
          </section>
          <section id="chat" className={`stream-column stream-column__chat ${videojs.browser.IS_SAFARI ? "stream-column__chat--hide-phone" : ""} `}>
            <iframe title="chat" frameBorder="0"
              scrolling="no"
              id="chat_embed"
              src={`https://www.twitch.tv/embed/${TWITCH_ACCOUNT_TEST}/chat?parent=www.mixbprod.com`}
              className="chat"
              style={{ "border": "1px solid #eee" }}
              width="100%">
            </iframe>
          </section>
          <section id="content">
            {videojs.browser.IS_SAFARI && <div>
              <h2>
                Mobile users:
              </h2>
              <p>
                To be able to chat while watching the visuals, we recommend using a separate device.
              </p>
            </div>
            }
            <ul className="actions">
              <li>
                <Link className="button" to="/live-stream">back to the DJ stream</Link>
              </li>
              <li>
                <Link className="button" to="/">back to home page</Link>
              </li>
            </ul>
          </section>
        </main>
        <section>
        </section>
      </Layout>
    )
  }


}

export default LiveStream

export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              link
              stream
              location
              startTime
              endTime
              bannerImage
            }
            fields {
              date
            }
          }
        }
      }
    }
  `